export function getKey(key) {
    try {
        return localStorage.getItem(key);
    }
    catch (_) {
        return null;
    }
}
export function setKey(key, value) {
    try {
        localStorage.setItem(key, value);
        return true;
    }
    catch (_) {
        return false;
    }
}
