export default {
    sounds: 'https://outloudvi.github.io/ui-assets/sounds.json',
    categories: 'https://outloudvi.github.io/ui-assets/categories.json',
    assets_path: 'https://outloudvi.github.io/ui-assets/assets/',
    notice: 'https://outloudvi.github.io/ui-assets/notice.json',
};
export const testingConfig = {
    sounds: 'sounds.json',
    categories: 'categories.json',
    assets_path: 'assets/',
};
