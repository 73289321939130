export default {
    'Do Not Click Me': '没事不要点',
    Repeat: '洗脑循环',
    'Dark Theme': '暗色主题',
    'Light Theme': '浅色主题',
    GitHub: '代码猫',
    Telegram: '观星台',
    Discord: 'Discord',
    English: '英文',
    'Toggle chorus mode': '多重吟唱模式开关',
    'Music board': '声音面板',
    Back: '返回',
    'Suisei Remix Editor': '星音混响编辑器',
    experimental: '实验性',
    Play: '播放',
    Export: '导出',
    Clear: '清除',
    'Your device screen is too small to run this editor. Sorry~': '你的屏幕尺寸过小，不适合运行此编辑器。抱歉～',
    'long-editor-description': "你可以在此页对星音剪辑进行组合，并且导出你的作品。<br>轨道总长度为 5 秒。拖动声音剪辑按钮到轨道上以添加音频片段。<br>在创作衍生作品时，请遵守 <a href='https://hololive.tv/terms'>Hololive 二创条例</a>。<br>（这是一个实验性编辑器。在将来可能会有大量改变及功能添加。）",
    'Error in sound playing:': '在播放声音时出现错误',
    "We've known about it and will work on it soon.": '我们已经获知此问题并将着手处理。',
    'Sound list fetch error:': '音频列表获取失败：',
    'Voices are still loading. Please be patient...': '音频仍在加载，请稍等...',
    'Unrecognized sound list.': '未能识别声音列表。',
    'Still loading...': '仍在加载...',
    Uncategorized: '未分类',
    Starbuttons: '雨按钮',
    'Loading...': '正在加载...',
    'Failed to fetch sounds list. Please try refreshing.': '声音列表获取失败，请刷新重试。',
    'But Watame did nothing wrong!': '但是 Watame 才没有做错呢！',
    'Click to close': '点击关闭',
    'or it will automatically close later': '或等待其稍后自行关闭',
    '3rd YouTube live - STARt IN THE SCREEEN!': '3rd YouTube live - STARt IN THE SCREEEN!',
    'Available at 21 JST on 10/19 →': '10月19日晚九点 (JST) 呈现 →',
};
