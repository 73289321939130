export default {
    'Do Not Click Me': 'Do Not Click Me',
    Repeat: 'Repeat',
    'Dark Theme': 'Dark Theme',
    'Light Theme': 'Light Theme',
    GitHub: 'GitHub',
    Telegram: 'Telegram',
    Discord: 'Discord',
    English: 'English',
    'Toggle chorus mode': 'Toggle chorus mode',
    'Music board': 'Music board',
    Back: 'Back',
    'Suisei Remix Editor': 'Suisei Remix Editor',
    experimental: 'experimental',
    Play: 'Play',
    Export: 'Export',
    Clear: 'Clear',
    'Your device screen is too small to run this editor. Sorry~': 'Your device screen is too small to run this editor. Sorry~',
    'long-editor-description': "Here you can remix with Suisei's voice clips and export your sparks.<br>The full track length is 5 seconds. Drag the voice clip button on wherever you want on the track.<br>Please comply to <a href='https://en.hololive.tv/terms'>Hololive's fan work guidelines</a> when creating derivative works.<br>(This editor is experimental and may change a lot and add many features in the future.)",
    'Error in sound playing:': 'Error in sound playing:',
    "We've known about it and will work on it soon.": "We've known about it and will work on it soon.",
    'Sound list fetch error:': 'Sound list fetch error:',
    'Voices are still loading. Please be patient...': 'Voices are still loading. Please be patient...',
    'Unrecognized sound list.': 'Unrecognized sound list.',
    'Still loading...': 'Still loading...',
    Uncategorized: 'Uncategorized',
    Starbuttons: 'Ui Buttons',
    'Loading...': 'Loading...',
    'Failed to fetch sounds list. Please try refreshing.': 'Failed to fetch sounds list. Please try refreshing.',
    'But Watame did nothing wrong!': 'But Watame did nothing wrong!',
    'Click to close': 'Click to close',
    'or it will automatically close later': 'or it will automatically close later',
    '3rd YouTube live - STARt IN THE SCREEEN!': '3rd YouTube live - STARt IN THE SCREEEN!',
    'Available at 21 JST on 10/19 →': 'Available at 21 JST on 10/19 →',
};
