export default {
    'Do Not Click Me': 'クリックしないでください',
    Repeat: 'Repeat',
    'Dark Theme': '暗色のテーマ',
    'Light Theme': '明色のテーマ',
    GitHub: 'GitHub',
    Telegram: 'Telegram',
    Discord: 'Discord',
    English: '英語',
    'Toggle chorus mode': 'コーラスモードをオンにする',
    'Music board': 'Music board',
    Back: 'Back',
    'Suisei Remix Editor': 'すいちゃんリミックスエディター',
    experimental: '試作版',
    Play: 'Play',
    Export: 'Export',
    Clear: 'Clear',
    'Your device screen is too small to run this editor. Sorry~': 'ごめんなさい。画面が小さいためこのエディターを利用できません。',
    'long-editor-description': "ここですいちゃんの音声素材をリミックスして保存することができます。<br>音声は5秒まで可能です。 追加したい場所に音声素材をドラッグしてください。<br>使用する際は<a href='https://hololive.tv/terms'>ホロライブ二次創作規約</a>に従ってください。<br>(このエディターは試作版です。今後新たな機能が追加されることがあります。)",
    'Error in sound playing:': 'Error in sound playing:',
    "We've known about it and will work on it soon.": "We've known about it and will work on it soon.",
    'Sound list fetch error:': 'Sound list fetch error:',
    'Voices are still loading. Please be patient...': 'Voices are still loading. Please be patient...',
    'Unrecognized sound list.': 'Unrecognized sound list.',
    'Still loading...': 'Still loading...',
    Uncategorized: 'Uncategorized',
    Starbuttons: 'Ui Buttons',
    'Loading...': 'Loading...',
    'Failed to fetch sounds list. Please try refreshing.': 'Failed to fetch sounds list. Please try refreshing.',
    'But Watame did nothing wrong!': 'But Watame did nothing wrong!',
    'Click to close': 'Click to close',
    'or it will automatically close later': 'or it will automatically close later',
    '3rd YouTube live - STARt IN THE SCREEEN!': '3rd YouTube live - STARt IN THE SCREEEN!',
    'Available at 21 JST on 10/19 →': '10月19日21時放送 →',
};
