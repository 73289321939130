import { addMessages, init, getLocaleFromNavigator, getLocaleFromQueryString, } from 'svelte-i18n';
import en from '../lang/en';
import ja from '../lang/ja';
import zh from '../lang/zh';
export default () => {
    addMessages('en', en);
    addMessages('zh', zh);
    addMessages('ja', ja);
    init({
        fallbackLocale: 'ja',
        initialLocale: (getLocaleFromQueryString('lang') || getLocaleFromNavigator()).split('-')[0],
    });
};
export function ln(lang, str) {
    var _a;
    return ((_a = str === null || str === void 0 ? void 0 : str.name_l10n) === null || _a === void 0 ? void 0 : _a[lang]) || (str === null || str === void 0 ? void 0 : str.name) || '';
}
